<template>
    <div class="user-wrapper">
        <div class="container">
            <div class="home" > <span @click="gointroduce()">返回</span> <span>订阅会员</span><span>/账单信息</span> </div>

            <div class="cont-left">
                <div class="title">账单信息</div>
                <div class="cont-inner">
                    <p>{{vipName}}</p>
                    <p style="font-size: 14px;">{{vipDesc}}</p>
                    <div class="m_switch" v-on:click="onChangeMode">
                        <div
                            :class="{m_switch_inner:isLeft,m_switch_inner_right:!isLeft ,move_left:ani==0,move_right:ani==1}">
                        </div>
                        <div class="m_switch_text_left"
                            :class="{color_white:isLeft,color_black:!isLeft,color_to_white:ani==0,color_to_black:ani==1}">
                            每年</div>
                        <div class="m_switch_text_right"
                            :class="{color_black:isLeft,color_white:!isLeft,color_to_white:ani==1,color_to_black:ani==0}">
                            每月</div>
                        <div class="m_switch_tip"><img src="@/assets/images/web/subscribe/热销.png"></div>

                    </div>
                    <p>选曲范围:{{vipRange}}</p>
                    <p>¥<span>{{vipCost}}</span>/{{vipDataType1}}</p>
                    <p>每{{vipDataType2}}仅需 <span style="color:red;">¥{{vipCost2}}</span></p>

                    <p>选择付款方式</p>
                    <div></div>
                    <div>
                        <div :class="{redborder:payType==2}" v-on:click="onChangepay(2)">
                            <div><img src="@/assets/images/web/introduce/alipay.svg">
                                <div style="color: black;font-weight: 400;">支付宝支付</div>
                            </div>
                        </div>
                        <div :class="{redborder:payType==1}" v-on:click="onChangepay(1)">
                            <div><img src="@/assets/images/web/introduce/wechatPay.svg">
                                <div style="color: black;font-weight: 400;">微信支付</div>
                            </div>
                        </div>
                        <div :class="{redborder:payType==3}" v-on:click="onChangepay(3)">
                            <div><img src="@/assets/images/web/introduce/companyPay.svg">
                                <div style="color: black;font-weight: 400;">公对公付款</div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <p>总计: <span>¥{{vipCost}}</span></p>
                    <p>
                        <span>支付即同意 <a href = './termsOfServiceVip'>《VIP服务协议》</a></span><span>✓</span>
                    </p>
                    <p @click="onOrder()">
                        <span>立即下单</span>
                    </p>
                    <p style="color:red;">*<span style="color:black;">音乐订阅会员服务属于虚拟商品，购买成功即视为己使用，概不支持退款，请您谅解。</span></p>
                    <div style="height: 20px;"></div>
                </div>

            </div>

            <div class="cont-right">
                <div class="title">权益说明</div>
                <div class="cont-inner-right">
                    <div style="height: 44px;background-color:#F4F6F6;vertical-align: top;">
                        <div style="width:100px;display: inline-block;">
                            <span style="color:black;float:right;line-height:44px;font-weight: 700;">选曲范围：</span>
                            <span style="color:red;float:right;line-height:44px;">*</span>
                        </div>
                        <div style="display: inline-block;margin-left:20px;vertical-align: top;width: 70%;">
                            <span v-if="vipType==3||vipType==6" style="line-height:44px;">全曲库（不包含音效）</span>
                            <div v-if="vipType==1||vipType==4||vipType==2||vipType==5">
                                <span style="line-height:44px;">仅限</span>
                                <span style="line-height:44px;color:red;">会员专区+音效</span>
                                <span style="line-height:44px;">，并非曲库全部音乐（</span>
                                <span style="line-height:44px;color:red;">购买前请务必仔细查看</span>
                                <span style="line-height:44px;">）</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="vipType==3||vipType==6">
                        <div style="width:100px;display: inline-block;">
                            <p style="float:right;line-height:44px;font-weight: 700;">权益说明：</p>
                        </div>
                        <div style="display: inline-block;margin-left:20px;vertical-align: top;width: 70%;">

                            <p style="line-height:22px;margin-top:12px;" v-if="vipType==3">
                                开通火花音悦省钱年卡，每月获得零售曲库6折券1张以及价值300元的满减券包（含50元代金券*6，单笔零售订单满100可用），权益累计发放12个月，当月立即到账。</p>
                            <p style="line-height:22px;margin-top:12px;" v-if="vipType==6">
                                开通省火花音悦省钱月卡，当月立即获得零售曲库8折券1张以及价值300元的满减券包（含50元代金券*6，单笔零售订单满100可用），零售一单即可回本。</p>
                        </div>
                    </div>
                    <div v-if="vipType==1||vipType==4||vipType==2||vipType==5">
                        <div style="width:100px;display: inline-block;">
                            <p style="float:right;line-height:44px;font-weight: 700;">授权场景：</p>
                            <p style="width:1px;height:244px;font-weight: 700;"></p>
                            <p style="float:right;line-height:44px;font-weight: 700;">授权地域：</p>
                            <p style="float:right;line-height:44px;font-weight: 700;">授权期限：</p>
                            <p style="float:right;line-height:44px;font-weight: 700;">授权主体：</p>
                            <p style="float:right;line-height:44px;font-weight: 700;">下载限制：</p>
                        </div>
                        <div style="display: inline-block;margin-left:20px;vertical-align: top;width: 70%;">
                            <p style="line-height:22px;margin-top:12px;">{{vipPara1}} <span
                                    style="float:right;line-height:22px;color:#3BBD82">✓</span></p>
                            <p style="line-height:22px;margin-top:12px;">{{vipPara2}}<span
                                    style="float:right;line-height:22px;color:#3BBD82">✓</span></p>
                            <p style="line-height:22px;margin-top:12px;">{{vipPara3}}<span
                                    style="float:right;line-height:22px;color:#3BBD82">✓</span></p>
                            <p style="line-height:22px;margin-top:12px;">{{vipPara4}}<span
                                    style="float:right;line-height:22px;color:#3BBD82">✓</span></p>
                            <p style="line-height:22px;margin-top:12px;">{{vipPara5}}<span
                                    style="float:right;line-height:22px;color:#3BBD82">✓</span></p>
                            <p style="line-height:22px;margin-top:12px;">{{vipPara6}}<span
                                    style="float:right;line-height:22px;color:#3BBD82">✓</span></p>
                            <p style="line-height:22px;margin-top:12px;">{{vipPara7}}<span
                                    style="float:right;line-height:22px;color:#3BBD82">✓</span></p>

                            <p style="line-height:32px;margin-bottom:22px;">{{vipPara8}}</p>
                            <p style="line-height:44px;">{{vipPara9}}</p>
                            <p v-if="vipType==2||vipType==5" style="line-height:44px;">{{vipPara10}}
                                <span
                                    class="tanhao">!</span>
                            </p>
                            <p v-if="vipType==1 || vipType==4 " style="line-height:44px;">当前仅支持授权<span
                                    style="color:red;"> 1</span> 家公司
                                <span
                                    class="tanhao">!</span>
                            </p>
                            <!-- <p v-else style="line-height:44px;">个人（禁止授权给企业）</p> -->
                            <p style="line-height:44px;">{{vipPara11}}</p>
                        </div>
                    </div>

                    <div style="height: 20px;"></div>
                </div>

            </div>
            <div style="height: 20px;"></div>
        </div>
    </div>
</template>

<script>
    import { tooltip as ATooltip, Popconfirm as APopconfirm } from 'ant-design-vue'
    import HeaderMixins from '@/components/common/header_mixins'
    import { copyStr } from '@/utils/function'

    export default {
        data() {
            return {
                vipType: 1,
                recommend: 3,
                companyCheckIdx: 3,
                personalCheckIdx: 3,
                payType: 2,
                vipData: {},
                needMoney: '',
                wxCode: "",
                aliCode: "",
                timer: null,
                wx_order_id: "",
                ali_order_id: "",
                modeType: 0,
                vipName: "",
                vipDesc: "",
                vipRange: "",
                vipCost: 0,
                vipCost2: 0,
                vipDataType1: "",
                vipDataType2: "",
                vipPara1: "",
                vipPara2: "",
                vipPara3: "",
                vipPara4: "",
                vipPara5: "",
                vipPara6: "",
                vipPara7: "",
                vipPara8: "",
                vipPara9: "",
                vipPara10: "",
                vipPara11: "",
                ani: -1,
                isLeft: true,
            }
        },
        mixins: [HeaderMixins],
        created() {
            this.vipType = this.$route.query.vipType || 1;

            // this.getVipData();
            this.setData();
        },
        mounted() {
            this.isLogin();
            this.$axios.VIPLIST().then(res => {
                if (res.data.code == 0) {
                    this.vipData = res.data.data.list;
                    
                }
            })
        },
        watch: {
            $route() {
                this.vipType = this.$route.query.vipType || 1;
                console.log(this.vipType);
                this.setData();
            }
        },
        computed: {
        },

        components: {
            ATooltip,
            APopconfirm,
        },
        beforeDestroy() {
            clearInterval(this.timer);
            this.timer = null;
        },
        methods: {
            gointroduce(){
                this.$router.push({path:'/subscribe_introduce_v2'});
            },
            onOrder() {
                // console.log(this.vipData[1]);
                // console.log(this.vipData[2]);
                // console.log(this.vipData[3]);
                var vip_id = "";
                if (this.vipType == 1){ //企业年
                    vip_id = this.vipData[1][2]['vip_id'];
                }else if(this.vipType == 4){ //企业月
                    vip_id = this.vipData[1][0]['vip_id'];
                }else if(this.vipType == 2){ //个人年
                    vip_id = this.vipData[2][2]['vip_id'];
                }else if(this.vipType == 5){ // 个人月
                    vip_id = this.vipData[2][0]['vip_id'];
                }else if(this.vipType == 3){ //省钱卡年
                    vip_id = this.vipData[3][1]['vip_id'];
                }else if(this.vipType == 6){ // 省钱卡 月
                    vip_id = this.vipData[3][0]['vip_id'];
                }
                // console.log(this.vipType,vip_id);
                let params = {
                    'vip_id': vip_id,
                    'pay_way': this.payType, // 1 微信2支付宝3公对公
                };
                if (this.payType < 3){
                    this.$axios.order(params).then(res => {
                        // console.log(res.data);
                        if (res.data.code == 0) {
                            this.$router.push({path:'/pay/'+this.payType,query: {order_id:res.data.data.order_id}});
                            // let url = `/member?menukey=4`;
                            // this.$router.replace(url);
                        }
                    })
                }else{
                    this.$router.push({path:'/pay/'+this.payType,query: {vip_id:vip_id}});
                }
                
            },
            onChangepay(t) {
                this.payType = t;
            },
            setData() {
                if (this.vipType == 1 || this.vipType == 2 || this.vipType == 3) {
                    this.isLeft = true;
                } else {
                    this.isLeft = false;
                }
                if (this.vipType == 2) {
                    this.modeType = 0;
                    this.vipName = "个人会员";
                    this.vipDesc = "适合自媒体人、UP主等自由职业者";
                    this.vipRange = "限会员专区+音效";
                    this.vipCost = 299;
                    this.vipCost2 = 24.92;
                    this.vipDataType1 = "年";
                    this.vipDataType2 = "月";
                }
                else if (this.vipType == 5) {
                    this.modeType = 1;
                    this.vipName = "个人会员";
                    this.vipDesc = "适合自媒体人、UP主等自由职业者";
                    this.vipRange = "限会员专区+音效";
                    this.vipCost = 59;
                    this.vipCost2 = 1.97;
                    this.vipDataType1 = "月";
                    this.vipDataType2 = "日";
                }
                else if (this.vipType == 1) {
                    this.modeType = 0;
                    this.vipName = "企业会员";
                    this.vipDesc = "适合中小企业、乙方公司为甲方客户授权";
                    this.vipRange = "限会员专区+音效";
                    this.vipCost = 3999;
                    this.vipCost2 = 333.25;
                    this.vipDataType1 = "年";
                    this.vipDataType2 = "月";
                } else if (this.vipType == 4) {
                    this.modeType = 1;
                    this.vipName = "企业会员";
                    this.vipDesc = "适合中小企业、乙方公司为甲方客户授权";
                    this.vipRange = "限会员专区+音效";
                    this.vipCost = 399;
                    this.vipCost2 = 13.3;
                    this.vipDataType1 = "月";
                    this.vipDataType2 = "日";
                }
                else if (this.vipType == 3) {
                    this.modeType = 0;
                    this.vipName = "省钱卡";
                    this.vipDesc = "零售订单专享，优惠券每月免费送";
                    this.vipRange = "限会员专区+音效";
                    this.vipCost = 116;
                    this.vipCost2 = 9.67;
                    this.vipDataType1 = "年";
                    this.vipDataType2 = "月";
                } else if (this.vipType == 6) {
                    this.modeType = 1;
                    this.vipName = "省钱卡";
                    this.vipDesc = "零售订单专享，优惠券每月免费送";
                    this.vipRange = "限会员专区+音效";
                    this.vipCost = 12;
                    this.vipCost2 = 0.4;
                    this.vipDataType1 = "月";
                    this.vipDataType2 = "日";
                }
                if (this.vipType == 2 || this.vipType == 5) {
                    this.vipPara1 = "个人自媒体";
                    this.vipPara2 = "家庭生活视频";
                    this.vipPara3 = "个人影视作品";
                    this.vipPara4 = "课程教育";
                    this.vipPara5 = "毕业设计";
                    this.vipPara6 = "个人直播";
                    this.vipPara7 = "个人非商业用途";
                    this.vipPara8 = "中国";
                    this.vipPara9 = "随片永久";
                    this.vipPara10 = "个人（禁止授权给企业）";
                } else if (this.vipType == 1 || this.vipType == 4) {
                    this.vipPara1 = "连续剧/综艺";
                    this.vipPara2 = "商业广告/宣传片";
                    this.vipPara3 = "自媒体视频";
                    this.vipPara4 = "电影/动画/纪录片";
                    this.vipPara5 = "游戏/APP/硬件内置";
                    this.vipPara6 = "广播剧/有声读物";
                    this.vipPara7 = "线下/活动/硬件";
                    this.vipPara8 = "全球";
                    this.vipPara9 = "随片永久";
                    this.vipPara10 = "";
                }
                if (this.vipType == 1 || this.vipType == 2) {
                    this.vipPara11 = "不限量/天";
                } else if (this.vipType == 5) {
                    this.vipPara11 = "10首/天";
                } else if (this.vipType == 4) {
                    this.vipPara11 = "20首/天";
                }
            },
            onChangeMode() {
                console.log(this.vipType);
                if (this.vipType == 1) {
                    this.vipType = 4;
                    this.ani = 1;
                }
                else if (this.vipType == 4) {
                    this.vipType = 1;
                    this.ani = 0;
                }
                if (this.vipType == 2) {
                    this.vipType = 5;
                    this.ani = 1;
                }
                else if (this.vipType == 5) {
                    this.vipType = 2;
                    this.ani = 0;
                }
                if (this.vipType == 3) {
                    this.vipType = 6;
                    this.ani = 1;
                }
                else if (this.vipType == 6) {
                    this.vipType = 3;
                    this.ani = 0;
                }
                this.setData();
            },
            getVipData() {
                this.isLogin();
                let that = this;
                this.$axios.VIPLIST().then(res => {
                    if (res.data.code == 0) {
                        this.vipData = res.data.data.list;
                        this.needMoney = this.vipData[that.vipType][that.companyCheckIdx - 1].price;
                        this.prePay();
                    }
                })
            },
            switchVip(vipIdx) {
                this.isLogin();
                this.vipType = vipIdx;
                if (this.vipType == 1) {
                    this.needMoney = this.vipData[this.vipType][this.companyCheckIdx - 1].price;
                } else {
                    this.needMoney = this.vipData[this.vipType][this.personalCheckIdx - 1].price;
                }
                this.prePay();
            },
            Chatra() {
                window.Chatra('openChat', true)
            },
            switchPayType(payTypeIdx) {
                this.payType = payTypeIdx;
            },
            switchCompanyIdx(item) {
                this.isLogin();
                this.companyCheckIdx = item.vip_type;
                this.needMoney = item.price;
                this.prePay();
            },
            switchPersonalIdx(item) {
                this.isLogin();
                this.personalCheckIdx = item.vip_type;
                this.needMoney = item.price;
                this.prePay();
            },
            onCopyText(str) {
                try {
                    copyStr(str);
                    this.$message.success('复制成功', 1);
                } catch (error) {
                    console.error(error);
                }
            },
            isLogin() {
                if (!this.$store.state.login) {
                    this.changeLoginAndRegisterDialog(true);
                }
            },
            comPay(pay_way = 3) {
                let vip_id = "";
                if (this.vipType == 1) {
                    vip_id = this.vipData[this.vipType][this.companyCheckIdx - 1].vip_id;
                } else {
                    vip_id = this.vipData[this.vipType][this.personalCheckIdx - 1].vip_id;
                }
                //生成订单
                let params = {
                    'vip_id': vip_id,
                    'pay_way': pay_way,
                };
                this.$axios.viporgPay(params).then(res => {
                    if (res.data.code == 0) {
                        //let data = res.data.data;
                        //let order_id = data.order_id;
                        let url = `/member?menukey=4`;
                        this.$router.replace(url);
                    }
                })
            },
            prePay(pay_way = 1) {
                window.clearInterval(this.timer);
                let vip_id = "";
                if (this.vipType == 1) {
                    vip_id = this.vipData[this.vipType][this.companyCheckIdx - 1].vip_id;
                } else {
                    vip_id = this.vipData[this.vipType][this.personalCheckIdx - 1].vip_id;
                }
                //生成订单
                let params = {
                    'vip_id': vip_id,
                    'pay_way': pay_way,
                };
                let that = this;
                this.$axios.qrCode(params).then(res => {
                    if (res.data.code == 0) {
                        let data = res.data.data;
                        if (pay_way == 1) {
                            that.wxCode = data.pay_data;
                            that.wx_order_id = "";
                            that.wx_order_id = data.order_id
                        }
                        if (pay_way == 2) {
                            that.aliCode = data.pay_data;
                            that.ali_order_id = "";
                            that.ali_order_id = data.order_id;
                        }
                        let oid = that.wx_order_id;
                        if (that.ali_order_id != "") {
                            oid = oid + ',' + that.ali_order_id;
                        }
                        that.timer = setInterval(() => {
                            that.checkPayStatus({ 'order_id': oid });
                        }, 4000);
                    }
                })
            },
            checkPayStatus(params) {
                this.$axios.PayCheckOrder(params).then(res => {
                    if (res.data.code == 0) {
                        let pay_status = res.data.data ? res.data.data.pay_status : '';
                        if (pay_status == '1') {
                            clearInterval(this.timer);
                            this.$message.success("您的订单已支付成功。");
                            this.$router.replace('/allmusic');
                        }
                    }
                })
            },
        }
    }
</script>
<style>
    .app-outer {
        background: #F9FAFA;
    }
</style>
<style lang="scss" scoped>
    .user-wrapper {
        width: 100%;
        padding-top: 24px;
        background: #F9FAFA;
        vertical-align: top;

        .user-main {
            display: inline-block;
            width: 48%;
            height: 912px;
            margin-left: 1%;
            background: #FFFFFF;

            .user-info {
                height: 160px;
                width: 90%;
                margin: 0 auto;
                text-align: center;

                div:nth-child(1) {
                    width: 100%;
                    height: 109px;
                    padding-top: 29px;
                    text-align: center;
                }

                div:nth-child(3) {
                    width: 40%;
                    margin: 0 auto;
                    margin-top: -40px;
                    text-align: right;
                    margin-right: 38%;
                }
            }

            .user-desc {
                width: 90%;
                text-align: left;
                margin-left: 30%;

                p {
                    margin-top: 20px;
                }

                p:nth-child(1) {
                    font-size: 18px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 25px;
                    width: 101px;
                }

                p:not(nth-child(1)) {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                }
            }
        }

        .cont-main {
            display: inline-block;
            vertical-align: top;
            width: 48%;
            margin-left: 2%;
            background: #FFFFFF;
            height: 912px;

            .sub-header {
                width: 100%;
                line-height: 60px;
                border-bottom: 1px solid #F0F0F6;

                span {
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                }

                span[selected] {
                    color: #FF7B13;
                }

                span:nth-child(2) {
                    width: 6.4%;
                    text-align: center;
                }

                span:nth-child(1) {
                    width: 46.8%;
                    text-align: right;
                    cursor: pointer;
                }

                span:nth-child(3) {
                    width: 46.8%;
                    text-align: left;
                    cursor: pointer;
                }

                span:nth-child(1):hover,
                span:nth-child(3):hover {
                    color: #FF7B13;
                }
            }

            .sub-cont {
                height: 294px;
                padding-top: 63px;
                text-align: center;

                .cont-item:not(:nth-child(1)) {
                    margin-left: 2%;
                }

                .cont-item:hover {
                    cursor: pointer;
                    border: 1px solid RGBA(244, 66, 24, 1);
                }

                .cont-item[selected] {
                    border: 1px solid RGBA(244, 66, 24, 1);
                }

                .cont-item {
                    display: inline-block;
                    width: 23.5%;
                    height: 200px;
                    border-radius: 2px;
                    border: 1px solid #EEEEEE;
                    text-align: center;

                    .ant-row:nth-child(1) {
                        text-align: left;
                        margin-top: -15px;
                        margin-left: -15px;
                        line-height: 74px;
                        height: 74px;
                    }

                    .ant-row:nth-child(2) {
                        margin-top: -30px;
                        font-size: 18px;
                        font-weight: 500;
                        color: #F44218;
                        line-height: 25px;
                        height: 25px;

                        span {
                            display: inline-block;
                            margin-left: 1%;
                        }
                    }

                    .ant-row:nth-child(3) {
                        margin-top: 21px;

                        span:nth-child(1) {
                            font-size: 34px;
                            font-weight: 500;
                            color: #F44218;
                            line-height: 48px;
                        }

                        span:nth-child(2),
                        span:nth-child(3) {
                            font-size: 14px;
                            font-weight: 400;
                            color: #333333;
                            line-height: 20px;
                        }

                        span:nth-child(3) {
                            color: rgba(244, 46, 37, 1);
                        }

                        span:nth-child(4) {
                            display: block;
                            width: 90%;
                            margin: 0 auto;
                            margin-top: 13px;
                            border-bottom: 1px solid #EAEAEA;
                        }
                    }
                }

                .ant-row:nth-child(4) {
                    margin-top: 13px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    height: 20px;
                }

                .ant-row:nth-child(5) {
                    margin-top: 4px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 17px;
                    height: 17px;
                }

                .ant-row:nth-child(6) {
                    margin-top: -21px;
                    text-align: right;
                    line-height: 17px;
                    height: 17px;
                }
            }

            .sub-cont,
            .pay-cont,
            .pay-footer {
                width: 94.6%;
                margin: 0 auto;
            }

            .pay-cont {
                height: 494px;
                border-radius: 2px;
                border: 1px solid rgba(4, 4, 5, 0.1);

                .pay-money,
                .pay-item {
                    width: 86%;
                    margin: 0 auto;
                }

                .pay-money {
                    line-height: 48px;
                    margin-top: 33px;
                    margin-bottom: 38px;
                    text-align: center;

                    span {
                        height: 48px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                    }

                    span:nth-child(2) {
                        font-size: 34px;
                        font-weight: 500;
                        color: rgba(244, 66, 24, 1);
                    }
                }

                .pay-item {
                    .pay-item-type {
                        width: 45%;
                        line-height: 54px;
                        display: inline-block;
                        border-radius: 2px;
                        border: 1px solid #E5E5E5;
                        margin-bottom: 43px;
                        cursor: pointer;

                        &[selected] {
                            border: 1px solid #4987D7;
                        }

                        div {
                            display: inline-block;
                            padding: 0 3%;
                        }

                        div:nth-child(1) {
                            width: 35%;
                        }

                        div:nth-child(2) {
                            width: 65%;
                            text-align: right;
                        }
                    }

                    .pay-item-type:hover {
                        border: 1px solid #4987D7;
                    }

                    .pay-item-type:nth-child(1) {
                        margin-right: 5%;
                    }

                    .pay-item-type:nth-child(2) {
                        margin-left: 5%;
                    }

                    .pay-item-code {
                        .code-info {
                            display: inline-block;
                            width: 50%;
                            text-align: center;
                        }

                        .code_wechat,
                        .code_alipay {
                            display: inline-block;
                            padding: 9px;
                            height: 164px;
                            width: 164px;
                            border-radius: 2px;
                            border: 1px solid #53A928;
                            font-size: 14px;
                            font-weight: 400;
                            color: #333333;
                            cursor: pointer;

                            img,
                            span {
                                width: 146px;
                                line-height: 146px;
                                display: inline-block;
                            }

                            span {
                                background: #ECECEC;
                            }
                        }

                        .code_alipay {
                            border: 1px solid #01A2E9;
                        }

                        .code-info div {
                            margin-top: 12px;
                        }
                    }

                    .pay-item-code,
                    .pay-item-company {
                        height: 200px;
                    }

                    .pay-item-company {
                        .ant-row {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 22px;
                        }

                        .ant-row:nth-child(1) {
                            margin-bottom: 22px;
                            color: rgba(0, 0, 0, 0.85);
                        }

                        .ant-row:nth-child(2),
                        .ant-row:nth-child(3),
                        .ant-row:nth-child(4) {
                            margin-top: 5px;
                            font-size: 14px;

                            span:nth-child(1) {
                                color: #262626;
                            }

                            span:nth-child(2) {
                                color: rgba(0, 0, 0, 0.85);
                            }

                            span:nth-child(3) {
                                font-size: 12px;
                                color: #666666;
                                margin-left: 1%;
                                cursor: pointer;
                            }
                        }

                        .ant-row:nth-child(5) {
                            margin-top: 27px;
                            color: rgba(0, 0, 0, 0.85);
                        }

                        .ant-row:nth-child(6) {
                            width: 100px;
                            margin: 0 auto;
                            margin-top: 27px;
                            line-height: 40px;
                            background: #FF872A;
                            border-radius: 7px;
                            text-align: center;
                            cursor: pointer;

                            span {
                                font-size: 16px;
                                font-weight: 500;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }

            .pay-footer {
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                line-height: 12px;
                margin-top: 25px;
                text-align: left;
            }
        }
    }

    /*  -----------------------      */
    .home {
        width: 48%;
        margin-left: 1%;
        color: #0379F8;
        span:nth-child(1){
            cursor: pointer;font-weight: 400;
        }
        span:nth-child(2) {
            color: black;
        }
    }

    .cont-left,
    .cont-right {
        display: inline-block;
        width: 48%;
        /* height: 670px; */
        margin-left: 1%;
        vertical-align: top;

        .title {
            margin-top: 18px;
            margin-bottom: 14px;
            font-weight: 800;
            font-size: 18px;
        }
    }

    .cont-inner {

        width: 100%;
        height: 90%;
        background: #FFFFFF;

        p {
            margin-bottom: 0px;
        }

        p:nth-child(1) {
            width: 100%;
            text-align: center;
            font-size: 24px;
            padding-top: 32px;
        }

        p:nth-child(2) {
            width: 100%;
            text-align: center;
            font-size: 24px;
            margin-top: 14px;
        }

        p:nth-child(4) {
            width: 100%;
            text-align: center;
            font-size: 14px;
            margin-top: 14px;
        }

        p:nth-child(5) {
            width: 100%;
            text-align: center;
            font-size: 14px;
            margin-top: 14px;

            span {
                font-size: 32px;
                font-weight: 500;
                color: red;
            }
        }

        p:nth-child(6) {
            width: 100%;
            text-align: center;
            font-size: 14px;
            /* margin-top:14px; */
        }

        p:nth-child(7) {
            width: 90%;
            font-size: 14px;
            margin-left: 5%;
        }

        div:nth-child(8),
        div:nth-child(10) {
            border: 1px solid #E6E6E6;
            border-bottom: none;
            width: 90%;
            /* left:50%; */
            margin-left: 5%;
            margin-top: 8px;
        }

        div:nth-child(9) {
            width: 90%;
            margin-left: 5%;

            div {
                margin-top: 14px;
                margin-bottom: 14px;
                margin-right: 1.5%;
                margin-left: 1.5%;
                background-color: #F6F6F6;
                width: 30%;
                height: 44px;
                display: inline-block;

                div {
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;

                    img {
                        /* height: 24px;
                        margin-top:5px; */
                    }

                    div {
                        width: 72px;
                        font-size: 12px;
                        font-weight: 300;
                    }

                    /* width: 500px;
                    height: 200px; */
                }

            }

        }

        p:nth-child(11) {
            width: 90%;
            font-size: 14px;
            margin-left: 5%;
            margin-top: 44px;
            text-align: left;

            span {
                /* width:50%; */
                color: red;
                text-align: right;
                /* margin-left:85%; */
                float: right;
                /* right:0; */
                /* position: absolute; */
            }
        }

        p:nth-child(12) {
            width: 90%;
            margin-left: 5%;
            padding-top: 13px;
            padding-bottom: 13px;

            span:nth-child(1) {
                float: right;
                font-size: 12px;
            }

            span:nth-child(2) {
                margin-top: 4px;
                margin-right: 4px;
                float: right;
                color: #999999;
                width: 12px;
                height: 12px;
                border: 1px solid #999999;
                font-size: 9px;
                line-height: 9px;
            }
        }

        p:nth-child(13) {
            width: 90%;
            margin: 0 auto;
            margin-top: 18px;
            line-height: 30px;
            background: linear-gradient(180deg, #FF992B 0%, #FF7B13 100%);
            border-radius: 4px;
            text-align: center;
            cursor: pointer;
            height: 44px;

            span {
                font-size: 16px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 44px;
            }
        }

        p:nth-child(14) {
            width: 90%;
            font-size: 12px;
            margin-left: 5%;
            margin-top: 8px;
        }
    }

    .redborder {
        border-radius: 2px;
        border: 1px solid #FF5B5B;
    }

    .cont-inner-right {
        width: 100%;
        height: 90%;
        background: #FFFFFF;
    }
    .tanhao{
        float:right;
        height:12px;
        width:12px;
        border-radius:6px; 
        background-color:#EE1E1E;
        margin-top:12px;
        line-height: 12px;
        color:white;
        text-align: center;
        font-size: 8px;
    }
    .m_switch {
        /* /deep/ .ant-switch-inner{width: 200px;background-color: #ff0000} */
        width: 216px;
        height: 40px;
        background: #F4F3F2;
        border-radius: 20px;
        position: relative;
        left: 50%;
        margin-left: -108px;
        margin-top: 23px;
    }

    .m_switch_inner {
        position: absolute;
        left: 2px;
        margin-top: 2px;
        width: 106px;
        height: 36px;
        background: #FF7D15;
        border-radius: 20px;
    }

    .m_switch_inner_right {
        position: absolute;
        left: 108px;
        margin-top: 2px;
        width: 106px;
        height: 36px;
        background: #FF7D15;
        border-radius: 20px;
    }

    .m_switch_text_left {
        position: absolute;
        /* left: 12px; */
        text-align: center;
        font-size: 20px;
        line-height: 36px;
        width: 106px;
    }

    .m_switch_text_right {
        position: absolute;
        left: 106px;
        text-align: center;
        font-size: 20px;
        line-height: 36px;
        width: 106px;
    }

    .m_switch_tip {
        position: absolute;
        left: 70px;
        top: -10px;
    }

    .color_white {
        color: #fff;
    }

    .color_black {
        color: #000;
    }

    .color_to_white {
        /* color: #fff; */
        animation: color_white 0.6s;
        animation-fill-mode: forwards;
    }

    .color_to_black {
        /* color: #000; */
        animation: color_black 0.6s;
        animation-fill-mode: forwards;
    }

    @keyframes color_white {
        from {
            color: #000;
        }

        to {
            color: #fff;
        }
    }

    @keyframes color_black {
        from {
            color: #fff;
        }

        to {
            color: #000;
        }
    }

    .move_left {
        animation: move_left 0.6s;
        animation-fill-mode: forwards;
    }

    .move_right {
        animation: move_right 0.6s;
        animation-fill-mode: forwards;
    }

    @keyframes move_right {
        from {
            left: 2px;
        }

        to {
            left: 108px;
        }
    }

    @keyframes move_left {
        from {
            left: 108px;
        }

        to {
            left: 2px;
        }
    }
</style>